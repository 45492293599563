<template>
  <v-container class="pa-0" fluid>
    <kurcc-site-branch-list :site-id="$route.params.site_id"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccSiteBranchesPage',
  components: {
    KurccSiteBranchList: () => import('@/modules/more/components/KurccSiteBranchList')
  }
}
</script>
